export const scoring = {
  right: 15,
  fast: 25,
  wrong: -5,
  malus: -1,
  bonus: 1
}

export const timer = {
  total: 20,
  fast: 5
}

export const socials = [
  {
    name: 'Partager sur Facebook',
    color: 'red',
    icon: 'facebook',
    url: 'https://www.facebook.com/sharer/sharer.php?u=https://runleprogramme.brig.ht/'
  },
  {
    name: 'Partager sur Twitter',
    color: 'blue',
    icon: 'twitter',
    url: `https://twitter.com/share?url=https://runleprogramme.brig.ht/&text=${encodeURIComponent('«Run le programme» : un jeu où vous engrangez des votes et devenez incollable sur le programme des candidats ! Made by @brig_ht, 3D experiences.')}`
  },
  {
    name: 'Partager sur Linkedin',
    color: 'red',
    icon: 'linkedin',
    url: 'https://linkedin.com/shareArticle/?url=https://runleprogramme.brig.ht/'
  },
  {
    name: 'Copier le lien',
    color: 'blue',
    icon: 'link',
    slug: 'copy',
  }
]

export const sounds = {
  vote: '/sounds/vote.mp3',
  end: '/sounds/end.mp3',
  hit: '/sounds/hit.mp3',
  cta: '/sounds/cta.mp3',
  next: '/sounds/next.mp3',
  wrong: '/sounds/wrong.mp3',
  right: '/sounds/right.mp3',
  background: '/sounds/background.mp3'
}

export default { scoring, timer, socials, sounds }