import DebugStore from "../../store/DebugStore"
import gui from "../utils/gui"

const gameConfig = {
  speed: 1,
  cameraToTranslate: .03,
  characterToTranslate: 6.1,
  coinSpeed: .65,
  decorSpeed: .45,
  barriersSpeed: .3,
  globalSpeed: 0,
  logoSpeed: .6,
  coinY: 4.5,
  zSpace: 5,
  hitCoin: +5,
  hitObstacle: -2,
  questionPerCharacter: 4,
  timeToValidateAnswer: 1,
  barrierSpeed: .4,
  bendY: 0.0065,
  bendX: 0.002,
  xLaneCoin: {
    1: 6.5,
    2: 0,
    3: -6
  },
  xLaneCorona: {
    1: 6.5,
    2: 0.01,
    3: -6.5
  },
  xLaneMoney: {
    1: 6.5,
    2: 0,
    3: -6.5
  },
  xLaneSurvey: {
    1: 6.5,
    2: 0.1,
    3: -6.5
  },
  xLaneTwitter: {
    1: 6.5,
    2: -0.1,
    3: -6.5
  },
  runModeTiming: {
    min: 10, max: 20
  },
  minRunModeTiming: 10,
  maxRunModeTiming: 20,
  speedSpawner: 1.5,
  obstacleProbapility: .6,
  maxCoinPerSpawn: 3,
  minCoinPerSpawn: 1,
}


const slugCharacter = {
  'info-candidat-1': 'macron',
  'info-candidat-2': 'lepen',
  'info-candidat-3': 'melanchon',
  'info-candidat-4': 'arthaud',
  'info-candidat-5': 'roussel',
  'info-candidat-6': 'lassalle',
  'info-candidat-7': 'jadot',
  'info-candidat-8': 'hidalgo',
  'info-candidat-9': 'pecresse',
  'info-candidat-10': 'dupont',
  'info-candidat-11': 'zemmour',
  'info-candidat-12': 'poutou',
}


if (DebugStore.useGui.current) {
  const config = gui.folder('Game Config')

  config.add(gameConfig, 'globalSpeed', 0, 1)
  config.add(gameConfig, 'coinSpeed', .1, 3)
  config.add(gameConfig, 'decorSpeed', .1, 3)
  config.add(gameConfig, 'barriersSpeed', .1, 1)
  config.add(gameConfig, 'coinY', 1, 6)
  config.add(gameConfig, 'speedSpawner', 1, 3)
  config.add(gameConfig, 'obstacleProbapility', .1, 1)
  config.add(gameConfig, 'maxCoinPerSpawn', 1, 10, 1)
  config.add(gameConfig, 'minCoinPerSpawn', 1, 10, 1)
  config.add(gameConfig, 'minRunModeTiming', 3, 40, 1)
  config.add(gameConfig, 'maxRunModeTiming', 5, 60, 1)
  config.add(gameConfig, 'questionPerCharacter', 1, 10, 1)
}


export {
  gameConfig,
  slugCharacter
}