import { createApp, mixin } from 'vue'
import { createRouter } from 'vue-router'

import { store } from '@/store'
import { router } from './router'

import { emitter } from '@/utils/emitter'

import Local from '@/plugins/Local'
import Device from '@/plugins/Device'
import Hub from '@/plugins/Hub'
import Offset from '@/plugins/Offset'
import Dom3d from '@/plugins/Dom3d'
import VueMatomo from 'vue-matomo'


import messages from '@/locales'

import AppImage from '@/components/common/AppImage'
import AppButton from '@/components/common/AppButton'

import extendedComponent from '@/mixins/extendedComponent'

import App from './App.vue'

class VueArtwork {
  constructor() {
    this.app = createApp(App)

    // router
    this.router = createRouter(router)
    this.app.use(this.router)

    // VueX
    this.app.use(store)

    // Mixin
    this.app.mixin(extendedComponent)

    // Handmade Plugins
    this.app.use(Local)
    this.app.use(Device)
    this.app.use(Hub)
    this.app.use(Offset)
    this.app.use(Dom3d)
    this.app.use(VueMatomo, this.matomoInit())

    this.app.component('app-image', AppImage)
    this.app.component('app-button', AppButton)

    this.app.mount('#app')

    this.router.beforeEach(this.beforeGuard)
  }

  beforeGuard(to, from, next) {
    if (!from.name) {
      // debug
      if (to.query.skip || to.name === 'Homepage') {
        next()
        return
      }
      // redirect to homepage
      else {
        next({ name: 'Homepage' })
        return
      }
    }

    emitter.emit('transition:on')

    setTimeout(() => {
      next()
      emitter.emit('transition:off')
    }, 2000)
  }

  matomoInit() {
    return {
      host: 'https://matomo.sorrychildren.com/',
      siteId: 2,
  
      // Enables automatically registering pageviews on the router
      router: this.router,
  
      // Enables link tracking on regular links. Note that this won't
      // work for routing links (ie. internal Vue router links)
      // Default: true
      enableLinkTracking: true,
  
      // Require consent before sending tracking information to matomo
      // Default: false
      requireConsent: false,
  
      // Whether to track the initial page view
      // Default: true
      trackInitialView: true,
  
      // Run Matomo without cookies
      // Default: false
      disableCookies: false,
  
      // Require consent before creating matomo session cookie
      // Default: false
      requireCookieConsent: false,
  
      // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
      // Default: false
      enableHeartBeatTimer: true,
  
      // Set the heartbeat timer interval
      // Default: 15
      heartBeatTimerInterval: 15,
  
      // Changes the default .js and .php endpoint's filename
      // Default: 'matomo'
      trackerFileName: 'piwik',
  
      // Overrides the tracker endpoint entirely
      // Default: undefined
      trackerUrl: undefined,
  
      // Overrides the tracker script path entirely
      // Default: undefined
      trackerScriptUrl: undefined,
  
      // Whether or not to log debug information
      // Default: false
      debug: true,
  
      // User ID
      // Default: undefined
      userId: 'some-user-id',
  
      preInitActions: [
        ['setCustomVariable', '1', 'VisitorType', 'Member'],
        ['appendToTrackingUrl', 'new_visit=1']
      ]
    }


  }

}

new VueArtwork()
