<template>
  <div class="u-wrapper-panel">
    <!-- QUESTION -->
    <transition name="custom" duration="1200">
      <question
        v-if="questionVisible && candidatesIndex >= 0 && questionIndex >= 0"
        :candidate="candidates[candidatesIndex]"
        :data="candidates[candidatesIndex].questions[questionIndex]"
        @answer="onAnswer"
      />
    </transition>
    <!-- SCORE -->
    <transition name="t-fader">
      <scoring v-if="!isResult" :score="totalScore" class="game__scoring" />
    </transition>
    <!-- TUTO GAME (FIRST) -->
    <tuto-game v-if="isTutoGame" @end="tutoGameEnded" />
    <!-- TUTO QUIZZ (SECOND) -->
    <tuto-quizz v-if="isTutoQuizz" @end="tutoQuizzEnded" />
    <!-- CANDIDATE TRANSITION -->
    <transition name="custom" duration="1200">
      <popin-candidate v-if="isTransition" :candidates="prevNextChar" />
    </transition>
    <!-- RESULTS -->
    <transition name="custom" duration="1200">
      <result @restart="onRestart" v-if="isResult" :candidates="candidates" />
    </transition>
    <!-- LEAVE -->
    <transition name="custom" duration="1200">
      <popin-leave v-if="isLeaving" @close="isLeaving = false" :candidates="candidates" />
    </transition>
    <!-- LOGO -->
    <router-link class="game__logo u-block" :to="{name: 'Homepage'}">
      <img class="u-block u-full-width" src="/images/logo.png">
    </router-link>

    <!-- LEAVE -->
    <transition name="t-fader">
      <app-button-round v-show="!$device.isMobile && !questionVisible && !isResult" class="game__close" @click="isLeaving = true" :big="true">
        <svg class="u-wrapper-panel u-fill-red" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#out"/>
        </svg>
      </app-button-round>
    </transition>
  </div>
</template>

<script>
import Question from '@/components/Question'
import Scoring from '@/components/Scoring'
import TutoGame from '@/components/TutoGame'
import TutoQuizz from '@/components/TutoQuizz'
import Start from '@/components/Start'
import Result from '@/components/Result'
import PopinCandidate from '@/components/PopinCandidate'
import PopinLeave from '@/components/PopinLeave'
import AppButtonRound from '@/components/common/AppButtonRound'

import { audio, Audio, audioBg } from '@/utils/Audio'
import { sounds } from '@/assets/data'

import { events } from '@/webGL/canvas/config/events'

const nbrCharacters = 3

export default {
  components: {
    Question,
    Scoring,
    TutoGame,
    TutoQuizz,
    PopinCandidate,
    Start,
    Result,
    PopinLeave,
    AppButtonRound
  },

  computed: {
    candidates () {
      return this.$store.getters['data/randomCandidates']
    },
    totalScore () {
      return this.$store.getters['data/score']
    },
    prevNextChar () {
      if (this.candidatesIndex === -1) {
        return []
      }
      else if (this.candidatesIndex === 0) {
        return [this.candidates[0]]
      }
      else {
        return [this.candidates[this.candidatesIndex], this.candidates[this.candidatesIndex - 1]]
      }
    }
  },

  data () {
    return {
      questionVisible: false,
      questionIndex: -1,
      candidatesIndex: 0,
      isTutoGame: false,
      isTutoQuizz: false,
      // isStart: false,
      isTransition: false,
      isLeaving: false,
      isResult: false
    }
  },

  created () {
    this.isInit = false
    this.$store.commit('data/randomCandidates', 3)
  },

  mounted () {
    this.$hub.on(events.SHOW_QUESTION, this.onQuestion)
    this.$hub.on(events.BONUS, this.onBonus)
    this.$hub.on(events.MALUS, this.onMalus)
    this.$hub.on(events.CANDIDATE_CHANGE, this.onChange)
    this.$hub.on(events.END, this.onEnd)

    if (this.candidates.length) {
      this.onInit()
    }
  },

  beforeUnmount () {
    this.$hub.emit(events.STOP)
    this.$hub.off(events.SHOW_QUESTION, this.onQuestion)
    this.$hub.off(events.BONUS, this.onBonus)
    this.$hub.off(events.MALUS, this.onMalus)
    this.$hub.off(events.CANDIDATE_CHANGE, this.onChange)
    this.$hub.off(events.END, this.onEnd)

    audioBg.reset()
  },

  methods: {
    onEnd () {
      audio.src = sounds.end
      audio.play()
      this.isResult = true
    },

    onRestart () {
      this.$store.commit('data/randomCandidates', 3)
      this.$hub.emit(events.RESET)
      this.$hub.emit(events.CANDIDATES, this.candidates.map(char => {
        return {
          slug: char.slug
        }
      }))
      this.isTransition = false
      this.isLeaving = false
      this.isResult = false

      requestAnimationFrame(() => {
        this.candidatesIndex = 0
        this.$store.commit('data/currentCandidate', this.candidates[this.candidatesIndex])
        this.tutoGameEnded()
      })
    },

    onInit() {
      if (this.isInit) { return }
      this.isInit = true

      this.$hub.emit(events.INIT)
      this.$hub.emit(events.CANDIDATES, this.candidates.map(char => {
        return {
          slug: char.slug
        }
      }))

      this.candidatesIndex = 0
      this.$store.commit('data/currentCandidate', this.candidates[this.candidatesIndex])

      if (this.$route.query.skip) {
        setTimeout(() => {
          this.$hub.emit(events.READY)
          this.$hub.emit(events.START)
          this.$store.commit('global/isFirstTuto')
        }, 1000)
      }
      else {
        setTimeout(() => {
          if (this.$store.getters['global/isFirstTuto']) {
            this.isTutoGame = true
          }
          else {
            this.tutoGameEnded()
          }
        }, 1500)
      }
    },
    tutoGameEnded () {
      this.isTutoGame = false

      this.$hub.emit(events.READY)
      this.onTransitionCandidates()
    },
    onTransitionCandidates () {
      if (this.candidatesIndex === 0) {
        this.$hub.emit(events.START)
      }
      this.isTransition = true
      setTimeout(() => {
        this.isTransition = false
      }, 5000)
    },
    tutoQuizzEnded () {
      this.isTutoQuizz = false
      setTimeout(this.onQuestion, 1000)
    },
    onQuestion () {
      if (this.$store.getters['global/isFirstTuto']) {
        this.isTutoQuizz = true
        this.$store.commit('global/isFirstTuto')
      }
      else {
        this.questionIndex++
        this.questionVisible = true
      }
    },
    onBonus () {
      const a = new Audio(true)
      a.src = sounds.vote
      a.play()
      this.$store.commit('data/bonus')
    },
    onMalus () {
      const a = new Audio(true)
      a.src = sounds.hit
      a.play()
      this.$store.commit('data/malus')
    },
    onChange () {
      this.candidatesIndex++
      this.onTransitionCandidates()
      this.$store.commit('data/currentCandidate', this.candidates[this.candidatesIndex])
    },
    onAnswer (value) {
      this.questionVisible = false
      if (!value.value) {
        this.$store.commit('data/wrong')
        audio.src = sounds.wrong
        audio.play()
      }
      else if (value.fast) {
        this.$store.commit('data/answer')
        audio.src = sounds.right
        audio.play()
      }
      else {
        this.$store.commit('data/answerFast')
        audio.src = sounds.right
        audio.play()
      }

      this.$hub.emit(events.HIDE_QUESTION, value)
    }
  },

  watch: {
    candidates () {
      this.onInit()
    },
    isLeaving () {
      if (this.isLeaving) {
        this.$hub.emit(events.PAUSE)
      }
      if (!this.isLeaving) {
        this.$hub.emit(events.RESUME)
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.game
  &__close
    position absolute
    bottom: $spacings.md
    right: $spacings.md

  &__logo
    position absolute
    top: $spacings.md
    left: $spacings.md
    width 225px
    max-width 25vw

  &__scoring
    position absolute
    top: $spacings.md
    right: $spacings.md
    
    +mq($until: 'tablet')
      right: 90px
      top 40px
</style>