<template>
  <div class="candidat-info u-relative u-pad-x-lg u-pad-y-md u-marg-t-md">
    <div class="candidat-info__background u-wrapper-panel" :style="{backgroundColor: data.color}" />
    <div class="u-row u-middle u-relative">
      <div class="u-w2of12 u-row u-left">
        <app-button-round :to="{name: 'Candidat', params: { candidat: prev.slug }}">
          <svg class="u-wrapper-panel u-fill-black" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#arrow-left"/>
          </svg>
        </app-button-round>
      </div>
      <div class="u-w8of12 u-center">
        <div>
          <div class="t-h5 u-bg-white u-radius u-pad-x-sm u-pad-y-xs u-blue u-inline-block">
            {{ data.party }}
          </div>
        </div>
        <div class="t-h2 u-white u-marg-t-sm">
          {{ data.name }}
        </div>
      </div>
      <div class="u-w2of12 u-row u-right">
        <app-button-round :to="{name: 'Candidat', params: { candidat: next.slug }}">
          <svg class="u-wrapper-panel" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#arrow-right"/>
          </svg>
        </app-button-round>
      </div>
    </div>
  </div>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  components: {
    AppButtonRound
  },

  computed: {
    prev () {
      return this.$store.getters['data/prevCandidate'](this.data)
    },
    next () {
      return this.$store.getters['data/nextCandidate'](this.data)
    }
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.candidat-info
  &__background
    content ''
    position absolute
    top 0
    left 0
    height 100%
    width 100%
    opacity .7
    border-radius 30px
    background $colors.blue
  
</style>