<template>
  <div v-if="!choosen">
    <div class="cookie u-pad-md u-row u-middle">
      <div class="u-w12of12--sm">
        <div class="u-right u-white t-text--small u-marg-b-xs u-center u-cursor-pointer" @click="onDecline">
          Continuer sans accepter
        </div>
        <div class="cookie__wrapper u-bg-white u-pad-x-md u-pad-y-sm u-pad-y-md--sm u-shodow u-radius">
          <div class="t-text">
            Nous utilisons des cookies
          </div>
          <div class="u-marg-t-xs u-marg-t-sm--sm">
            <checkbox-field @onChange="value => {isAnalytics = value}" :default="isAnalytics" name="Google Analytics" slug="analytics"/>
            <checkbox-field :default="true" name="Fonctionnels" slug="fonctionnals" class="u-pointer-none u-marg-t-xxs--sm"/>
          </div>
          <div class="u-marg-t-sm u-center">
            <app-button @click="onAccept" :isSmall="true">
              Valider
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxField from './CheckboxField'
import AppButton from '@/components/common/AppButton'

import { storage } from '@/utils/storage'

export default {
  components: {
    CheckboxField,
    AppButton
  },

  data () {
    return {
      isMore: false,
      choosen: false,
      isAnalytics: true,
      analytics: false
    }
  },

  created () {
    storage.getItem(`analytics`).then(res => {
      if (res !== null) {
        this.choosen = true
        // this.addAnalytics()
      }
    })
  },

  methods: {
    onDecline() {
      this.choosen = true
    },
    onAccept() {
      if (this.isAnalytics) {
        // this.addAnalytics()
      }

      this.choosen = true
    },
    addAnalytics() {
      storage.setItem(`analytics`, true)

      const head = document.querySelector('head')
      const script = document.createElement('script')
      // script.setAttribute('type', 'text/javascript')
      script.innerHTML = `var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="//matomo.brig.ht/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '3']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();`
      head.appendChild(script)
    }
  },

  watch: {
    $route (to, from) {
      if (from.name && !this.choosen) {
        this.onAccept()
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.cookie
  position absolute
  top 0
  right 0
  
  +mq($until: 'tablet')
    width 100%
    height 100%
    background-color rgba($colors.black, .5)
  
  &__wrapper
    width 300px

    +mq($until: 'tablet')
      width 100%
</style>