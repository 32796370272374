import Files from "./managers/assetsManager/Files"
import AppStore from "./store/AppStore"
import { Game, Keyboard, Viewport, Swipe } from "./controllers"
import canvas from "./canvas/canvas"
import FileLoader from "./managers/assetsManager/FileLoader"

// import SoundManager from './managers/soundManager/SoundManager'
import characterAnimations from "./canvas/components/character/animations"

import globalDatas from '@/assets/assetsDatas/global.json'
import gameDatas from '@/assets/assetsDatas/game.json'
import sounDatas from '@/assets/assetsDatas/main.json'

import { getGPUTier } from 'detect-gpu';
import CanvasStore from "./store/CanvasStore"
const PIXEL_RATIO = {0: 1.2,  1:1.2,  2 :1.2, 3: 1.85}
const PIXEL_RATIO_MOBILE = {0: window.devicePixelRatio *.5 , 1: 1, 2: 1.55, 3: window.devicePixelRatio}
class App {

    loadApp() {
        FileLoader.addGroup(globalDatas)
        FileLoader.addGroup(gameDatas)

        return Promise.resolve()
            .then(() => FileLoader.loadGroup('global'))
            .then(() => FileLoader.loadGroup('game'))
            .then(() => getGPUTier())

    }

    // loadQuizz() {
    //     // loadQuizz
    // }

    init() {
        Game.init()
        Viewport.init()
        Keyboard.init()
        Swipe.init()
        canvas.init({ parent: document.querySelector('#canvas') })

        Promise.all([this.loadApp()])
            .then((gpuTier) => {
                this.appReady(gpuTier)
            })
    }

    /**
   * init main sounds in sound manager
   *
   * @param {Object} event Loader event result
   * @memberof App
   */
    initMainSounds(files) {
        const mainSoundsData = sounDatas
        mainSoundsData.name = 'main_sounds'
        this._addSoundsFrom([mainSoundsData], files)
    }
    /**
   *
   * @param {Object} soundsData
   * @param {Object} event
   */
    _addSoundsFrom(soundsData, event) {
        // soundsData.forEach(data => {
        //     data.sound = event[data.name]
        //     SoundManager.add(data)
        // })
    }

    appReady(gpuTier) {
        Promise.resolve()
            .then(() => this.initMainSounds(Files.get('global')))
            .then(() => characterAnimations.setAnimations(Files.get('global')))
            .then(() => canvas.appLoaded())
            // .then(() => canvas.prerender())
            .then(() => canvas.appReady())
            .then(() => {
                CanvasStore.canvasPixelRatio.set(gpuTier[0].isMobile ? PIXEL_RATIO_MOBILE[gpuTier[0].tier] : PIXEL_RATIO[gpuTier[0].tier])
                AppStore.appReady.set(true)
                // if (AppStore.introSkipped.get()) {
                //     Game.ready()
                //     Game.start()
                //     AppStore.currentView.set('game')
                // }
                if (AppStore.gameInit.get()) AppStore.currentView.set('game')

                console.log('appis ready')
            })
    }


}


export default new App()

