
<template>
  <button
    class="t-button u-cursor-pointer u-center t-text"
    :class="{
      'is-white': isWhite,
      'is-blue': isBlue,
      'is-ghost': isGhost,
      'is-shiny': isShiny,
      'is-small': isSmall
    }"
  >
    <template v-if="url !== ''" >
      <a v-if="isUrlAbsolute" :href="url" target="_blank" class="u-relative" :class="metaClass">
        <slot/>
      </a>
      <router-link v-else :to="url" class="u-relative" :class="metaClass">
        <slot/>
      </router-link>
    </template>
    <router-link v-else-if="to.name" :to="to" class="u-relative" :class="metaClass">
      <slot/>
    </router-link>
    <span v-else class="u-relative" :class="metaClass">
      <slot/>
    </span>
  </button>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  computed: {
    metaClass () {
      let classs =  this.icon ? 'u-row u-center u-middle' : ''

      return classs + ' u-relative ' + this.innerClass
    }
  },

  props: {
    icon: {
      type: Boolean,
      default: false
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    isShiny: {
      type: Boolean,
      default: false
    },
    isGhost: {
      type: Boolean,
      default: false
    },
    isBlue: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    innerClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.t-button
  position relative
  padding: clamp(10px, .4em, 20px) clamp(20px, 1.4em, 40px)
  color: $colors.white
  background-color transparent
  z-index 0
  
  &.is-shiny
    &:before 
      content ''
      z-index -1
      border-radius 100px
      border: solid 2px $colors.red
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      animation 2s ease-in-out-quad infinite shiny
      
  
  &.is-small
    padding: clamp(5px, .5em, 10px) clamp(10px, 1.5em, 20px) clamp(7px, .7em, 12px)

  &:after
    content ''
    z-index -1
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    border-radius 100px
    background-color: $colors.red
    transition: transform .5s ease-in-out-quart

  &.is-white
    color: $colors.blue

    &:after
      background-color: $colors.white

  &.is-blue
    color: $colors.white

    &:after
      background-color: $colors.blue

  &.is-ghost
    color: $colors.blue

    &:after
      border: solid 2px $colors.blue
      background-color: transparent
  
  +mq($from: 'tablet')
    &:hover
      &:after
        transform scale(1.2, 1.2)

@keyframes shiny
  0%
    transform: translateZ(0) scale(.9)
    opacity 0
  20%
    opacity 1

  100%
    transform: translateZ(0) scale(1.4)
    opacity 0
</style>