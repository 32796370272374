<template>
  <router-link
    class="keypoint"
    :to="{...$local('Candidat'), params: {candidat: data.slug}}"
    :style="{
      left: `calc( ${position.x}% - ${width * .5}px )`,
      top: `calc( ${position.y}% - ${width * .5}px )`,
      height: `${width}px`,
      width: `${width}px`
    }">
    <div class="keypoint__main u-box-1by1" />
    <div class="keypoint__pulse" />
    <div class="keypoint__heart">
      <svg class="keypoint__heart__svg u-wrapper-panel u-fill-transparent u-stroke-black" >
        <use xlink:href="#plus"/>
      </svg>
    </div>
  </router-link>
</template>

<script>

export default {
  props: {
    position: {
      type: Object,
      default: {x: 0, y: 0}
    },
    width: {
      type: Number,
      default: 50
    },
    data: {
      type: Object,
      default: {}
    }
  },
  mounted () {
    this.onResize()
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.keypoint
  position absolute
  border-radius 50%

  &__main
    border-radius 50%
    background-color: $colors.white
    opacity .5

  &__heart
    position absolute
    background: $colors.white
    height 75%
    width 75%
    top 12.5%
    left 12.5%
    border-radius 50%

    &__svg
      padding 10px

  &__pulse
    position absolute
    border: solid 4px $colors.white
    height 150%
    width 150%
    top -25%
    left -25%
    border-radius 50%
    opacity 0
    will-change opacity, transform
    animation 2s ease-in-out-quad infinite pulse

@keyframes pulse
  0%
    transform: translateZ(0) scale(.5)
    opacity 0
  20%
    opacity 1

  100%
    transform: translateZ(0) scale(1)
    opacity 0

</style>