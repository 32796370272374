import DebugStore from "../../store/DebugStore"
import StatsJS from '../../libs/stats.min'

let container
let initialized = false
let visible = true

function setup() {
	if (initialized) return
	window.__toggleStats = function () {
		visible = !visible; container.style.display = visible ? 'block' : 'none'
	}

	container = document.createElement('div')
	container.className = '__stats'
	container.style.cssText = (
		'z-index:10000;position:fixed;' +
		'bottom:5px;left:5px;padding:10px;margin:0;' +
		'user-select:none;' +
		// 'display: none;' +
		'font-family:"Menlo", "Monaco", monospace;font-size:10px;font-weight:300;min-width:120px;' +
		'background:white;color:black;' +
		'transform:translateZ(0);' +
		'will-change:content,transform;' +
		'box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);'
	)
	// container.addEventListener( 'click', onClick )
	if (DebugStore.showStats.get()) showStats()
	initialized = true
}

function showStats() {
	if (!visible) return
	visible = true
	document.body.appendChild(container)
}

function hideStats() {
	if (!visible) return
	visible = false
	document.body.removeChild(container)
}

export class Stats {

	constructor() {
		setup()
		this.statsJS = new StatsJS()
		this.statsJS.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
		document.body.appendChild(this.statsJS.dom)

		this.items = {}
		this.nodes = {}
		if (!this.nodes.main) {
			this.nodes.main = document.createElement('div')
			container.append(this.nodes.main)
		}
	}

	add(items) {
		Object.assign(this.items, items)
		this.traverse()
	}



	element(name) {
		if (this.nodes.main) {
			const el = document.createElement('div')
			this.nodes[name] = el
			this.nodes.main.append(el)
		}
	}

	render(name, item, value) {
		this.nodes[name].textContent = name + ' : '
		this.nodes[name].textContent += item.callback && item.callback(value) || value
	}

	traverse(items) {
		for (const name in items) {
			const item = items[name]

			if (item.key) {
				const value = item.object[item.key]
				if (!this.nodes[name]) this.element(name)
				this.render(name, item, value)
			} else this.traverse(item)
		}
	}

	update() {
		this.traverse(this.items)
		this.statsJS.update()
	}

}

export class ThreeInfos extends Stats {

	constructor() {
		super()
		if (performance.memory) {
			this.memory = 0

			this.add({
				'memory (mb)': {

					object: this,
					key: 'memory',
					callback: (value) => value.toFixed(1)

				}
			})
		}
	}

	update() {
		performance.memory && (this.memory = performance.memory.usedJSHeapSize / 10485760)
		super.update()
	}

}
