<template>
  <div style="position: fixed; width: 0; height: 0; overflow: hidden; top: -99px">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="arrow-left" viewBox="0 0 20 19">
          <title>Arrow Left</title>
          <path d="M17.8158 7.4854H6.29139L10.6311 3.14569C11.3504 2.42487 11.3504 1.25887 10.6311 0.539515C9.91174 -0.179838 8.74574 -0.179838 8.02491 0.539515L0.539515 8.02491C-0.179838 8.74426 -0.179838 9.91174 0.539515 10.6311L8.02491 18.1165C8.38606 18.4762 8.85629 18.656 9.328 18.656C9.80118 18.656 10.2714 18.4762 10.6311 18.1165C11.3504 17.3971 11.3504 16.2311 10.6311 15.5103L6.29139 11.1706H17.8158C18.8329 11.1706 19.6584 10.3451 19.6584 9.328C19.6584 8.31088 18.8329 7.4854 17.8158 7.4854Z"/>
        </symbol>
        <symbol id="arrow-right" viewBox="0 0 20 19">
          <title>Arrow right</title>
          <path d="M2.18423 11.5146L13.7086 11.5146L9.36891 15.8543C8.64956 16.5751 8.64956 17.7411 9.36891 18.4605C10.0883 19.1798 11.2543 19.1798 11.9751 18.4605L19.4605 10.9751C20.1798 10.2557 20.1798 9.08826 19.4605 8.36891L11.9751 0.883514C11.6139 0.523837 11.1437 0.343999 10.672 0.343999C10.1988 0.343999 9.72859 0.523837 9.36891 0.883513C8.64956 1.60287 8.64956 2.76887 9.36891 3.48969L13.7086 7.82939L2.18423 7.82939C1.16711 7.82939 0.341624 8.65488 0.341624 9.672C0.341624 10.6891 1.16711 11.5146 2.18423 11.5146Z"/>
        </symbol>
        <symbol id="plus" viewBox="0 0 26 26">
          <title>Plus</title>
          <path d="M12.7227 2.44141V23.0007" stroke-width="4.56873" stroke-linecap="round"/>
          <path d="M23 12.7207L2.4407 12.7207" stroke-width="4.56873" stroke-linecap="round"/>
        </symbol>
        <symbol id="share" viewBox="0 0 24 24">
          <title>share</title>
          <path d="M5 7c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm11.122 12.065c-.073.301-.122.611-.122.935 0 2.209 1.791 4 4 4s4-1.791 4-4-1.791-4-4-4c-1.165 0-2.204.506-2.935 1.301l-5.488-2.927c-.23.636-.549 1.229-.943 1.764l5.488 2.927zm7.878-15.065c0-2.209-1.791-4-4-4s-4 1.791-4 4c0 .324.049.634.122.935l-5.488 2.927c.395.535.713 1.127.943 1.764l5.488-2.927c.731.795 1.77 1.301 2.935 1.301 2.209 0 4-1.791 4-4z"/>
        </symbol>
        <symbol id="talk" viewBox="0 0 31 25">
          <title>Talk</title>
          <path d="M3.53428 25C2.95074 25 2.36696 24.7082 2.01028 24.1894C1.55634 23.5409 1.58868 22.6977 2.04263 22.0492C2.88563 20.9142 3.56662 19.4877 3.98827 18.5149C1.39418 16.4721 0 13.8456 0 11.0571C0 4.96109 6.90661 0 15.4021 0C23.8975 0 30.8041 4.96109 30.8041 11.0571C30.8041 17.153 23.8975 22.1141 15.4021 22.1141C14.0079 22.1141 12.6135 21.9845 11.284 21.7251C9.50053 22.795 6.87413 24.157 4.05312 24.9351C3.89095 24.9677 3.69645 25 3.53428 25Z"/>
        </symbol>
        <symbol id="facebook" viewBox="0 0 14 25">
          <title>Facebook</title>
          <path d="M13.1229 0.00520167L9.85212 0C6.17755 0 3.80288 2.41487 3.80288 6.15253V8.98926H0.514293C0.23012 8.98926 0 9.21762 0 9.49929V13.6094C0 13.8911 0.230382 14.1191 0.514293 14.1191H3.80288V24.4902C3.80288 24.7719 4.033 25 4.31717 25H8.60784C8.89202 25 9.12214 24.7716 9.12214 24.4902V14.1191H12.9673C13.2514 14.1191 13.4816 13.8911 13.4816 13.6094L13.4831 9.49929C13.4831 9.36404 13.4288 9.23452 13.3325 9.13881C13.2362 9.0431 13.105 8.98926 12.9686 8.98926H9.12214V6.58453C9.12214 5.42872 9.40001 4.84197 10.919 4.84197L13.1223 4.84119C13.4062 4.84119 13.6364 4.61284 13.6364 4.33143V0.514965C13.6364 0.233815 13.4065 0.00572184 13.1229 0.00520167Z"/>
        </symbol>
        <symbol id="link" viewBox="0 0 26 26">
          <title>Link</title>
          <path d="M19.778 14.9287L23.3384 11.3678C25.9357 8.77095 25.9357 4.54488 23.3384 1.94817C20.7414 -0.64939 16.5155 -0.64939 13.9183 1.94817L8.78005 7.0864C6.18269 9.6832 6.18269 13.9093 8.78005 16.506C9.16356 16.8897 9.58334 17.215 10.0274 17.4854L12.778 14.7344C12.254 14.6092 11.757 14.3448 11.3492 13.937C10.1687 12.7565 10.1687 10.8354 11.3492 9.65521L16.4874 4.51698C17.6679 3.33647 19.589 3.33647 20.7692 4.51698C21.9497 5.69749 21.9497 7.61838 20.7692 8.79874L19.2596 10.3085C19.8818 11.7726 20.0539 13.3827 19.778 14.9287Z"/>
          <path d="M5.50856 10.3572L1.94817 13.9179C-0.64939 16.515 -0.64939 20.7408 1.94817 23.338C4.54498 25.9354 8.77105 25.9354 11.3683 23.338L16.506 18.1998C19.1033 15.6028 19.103 11.3769 16.506 8.78022C16.1229 8.39651 15.7031 8.07119 15.2589 7.80078L12.5082 10.5518C13.0319 10.6774 13.5288 10.9412 13.937 11.3492C15.1175 12.5297 15.1175 14.4506 13.937 15.631L8.79829 20.7692C7.61778 21.9497 5.69689 21.9497 4.51653 20.7692C3.33602 19.5887 3.33602 17.6678 4.51653 16.4875L6.02629 14.9779C5.40391 13.5138 5.23183 11.9033 5.50816 10.3573L5.50856 10.3572Z"/>
        </symbol>
        <symbol id="linkedin" viewBox="0 0 26 25">
          <title>Linkedin</title>
          <path d="M0.32557 24.5707H5.8361V7.99218H0.32557V24.5707Z"/>
          <path d="M3.0451 5.72838H3.08101C5.00266 5.72838 6.19861 4.45544 6.19861 2.86453C6.1627 1.23772 5.00266 0 3.1176 0C1.23254 0 0 1.23772 0 2.86453C0 4.45544 1.19594 5.72838 3.0451 5.72838Z"/>
          <path d="M20.1961 24.5707H25.7059V15.0646C25.7059 9.97253 22.9874 7.60308 19.3619 7.60308C16.4384 7.60308 15.1281 9.20987 14.3959 10.3395V7.99218H8.88568C8.95784 9.54753 8.88568 24.5707 8.88568 24.5707H14.3959V15.3125C14.3959 14.8171 14.4314 14.322 14.5771 13.9678C14.9755 12.9779 15.8822 11.9525 17.4044 11.9525C19.3982 11.9525 20.1961 13.4727 20.1961 15.7016V24.5707Z"/>
        </symbol>
        <symbol id="twitter" viewBox="0 0 30 25">
          <title>Twitter</title>
          <path d="M29.5459 2.86997C28.4472 3.35669 27.2764 3.67931 26.0558 3.83596C27.3115 3.07883 28.2699 1.88907 28.7205 0.455018C27.5497 1.15992 26.2571 1.65783 24.8795 1.93569C23.7678 0.740338 22.1834 0 20.455 0C17.1015 0 14.4018 2.74876 14.4018 6.11851C14.4018 6.60336 14.4424 7.06957 14.5421 7.5134C9.5064 7.26538 5.0505 4.82805 2.05713 1.11517C1.53454 2.0308 1.228 3.07883 1.228 4.20706C1.228 6.3255 2.30827 8.20339 3.91853 9.29058C2.94536 9.27194 1.99066 8.98662 1.18184 8.53719C1.18184 8.55584 1.18184 8.58008 1.18184 8.60433C1.18184 11.5769 3.28144 14.0459 6.03475 14.6147C5.5417 14.7508 5.00434 14.8161 4.44666 14.8161C4.05887 14.8161 3.66739 14.7937 3.29991 14.7116C4.08472 17.1341 6.31174 18.915 8.9598 18.9728C6.89897 20.6008 4.28231 21.5817 1.4496 21.5817C0.952856 21.5817 0.476428 21.5593 0 21.4978C2.68314 23.2451 5.86302 24.2428 9.29219 24.2428C20.4384 24.2428 26.5322 14.9186 26.5322 6.83647C26.5322 6.56607 26.523 6.30499 26.5101 6.04578C27.7122 5.18423 28.7223 4.10822 29.5459 2.86997Z"/>
        </symbol>
        <symbol id="out" viewBox="0 0 21 25">
          <title>Out</title>
          <path d="M13.1243 13.5159C12.6404 13.5159 12.2494 13.956 12.2494 14.498V18.4267C12.2494 18.9678 11.8573 19.4088 11.3743 19.4088H8.74946V4.67658C8.74946 3.83782 8.27348 3.08844 7.55783 2.80951L7.29877 2.71222H11.3743C11.8573 2.71222 12.2494 3.15318 12.2494 3.69449V6.64093C12.2494 7.18296 12.6404 7.62302 13.1243 7.62302C13.6081 7.62302 13.9992 7.18296 13.9992 6.64093V3.69449C13.9992 2.07003 12.8215 0.748047 11.3743 0.748047H1.96864C1.93532 0.748047 1.90744 0.764772 1.87508 0.769627C1.83294 0.765671 1.79273 0.748047 1.74996 0.748047C0.784861 0.748047 0 1.62889 0 2.71222V20.3909C0 21.2296 0.475979 21.979 1.19163 22.2579L6.4572 24.2282C6.63567 24.2901 6.81318 24.3196 6.99966 24.3196C7.96476 24.3196 8.74946 23.4386 8.74946 22.3552V21.3732H11.3743C12.8215 21.3732 13.9992 20.0512 13.9992 18.4267V14.498C13.9992 13.956 13.6081 13.5159 13.1243 13.5159Z"/>
          <path d="M20.7431 9.87477L17.2432 5.94624C16.9932 5.66533 16.6168 5.58081 16.2897 5.73295C15.9633 5.88527 15.7498 6.24369 15.7498 6.64059V9.58703H12.2501C11.767 9.58703 11.375 10.0269 11.375 10.5691C11.375 11.1113 11.767 11.5512 12.2501 11.5512H15.7498V14.4976C15.7498 14.8945 15.9633 15.253 16.2897 15.4053C16.6168 15.5574 16.9932 15.4729 17.2432 15.1922L20.7431 11.2635C21.0852 10.8795 21.0852 10.2587 20.7431 9.87477Z"/>
        </symbol>
        <symbol id="cross" viewBox="0 0 27 27">
          <title>Cross</title>
          <path d="M4 4L23 23" stroke-width="6.2388" stroke-linecap="round"/>
          <path d="M4 23L23 4" stroke-width="6.2388" stroke-linecap="round"/>
        </symbol>
        <symbol id="interrogation" viewBox="0 0 17 26">
          <title>Interrogation</title>
          <path d="M7.89013 16.9531C8.92082 16.9531 9.66718 16.1357 9.80935 15.2116L9.91597 14.5719C13.79 13.79 16.9176 11.9418 16.9176 7.42809V7.35701C16.9176 2.63004 13.4345 0 8.31662 0C5.189 0 2.80775 0.995151 0.924069 2.55896C0.284329 3.09208 0 3.8029 0 4.47818C0 5.82874 1.13732 6.96606 2.48788 6.96606C3.09208 6.96606 3.66073 6.71727 4.08723 6.3974C5.36671 5.43779 6.68173 4.97576 8.21 4.97576C10.2714 4.97576 11.4798 5.89982 11.4798 7.49917V7.57026C11.4798 9.31177 10.0581 10.2714 6.96606 10.6623C5.82874 10.8045 5.15346 11.6219 5.33117 12.7948L5.68658 15.2116C5.82874 16.1712 6.57511 16.9531 7.6058 16.9531H7.89013ZM4.51372 22.3198V22.3909C4.51372 24.0969 5.82874 25.4119 7.6058 25.4119C9.38285 25.4119 10.6979 24.0969 10.6979 22.3909V22.3198C10.6979 20.6138 9.38285 19.2988 7.6058 19.2988C5.82874 19.2988 4.51372 20.6138 4.51372 22.3198Z"/>
        </symbol>
        <symbol id="exclamation" viewBox="0 0 7 24">
          <title>Exclamation</title>
          <path d="M1.4647 14.4243C1.56604 15.3363 2.17409 15.9106 3.0186 15.9106C3.86311 15.9106 4.47116 15.3363 4.5725 14.4243L6.02506 1.7228C6.1264 0.743169 5.58591 0 4.67384 0H1.36336C0.451283 0 -0.0892042 0.743169 0.0121374 1.7228L1.4647 14.4243ZM0.0796983 21.0114V21.079C0.0796983 22.7005 1.32958 23.9503 3.0186 23.9503C4.70762 23.9503 5.9575 22.7005 5.9575 21.079V21.0114C5.9575 19.39 4.70762 18.1401 3.0186 18.1401C1.32958 18.1401 0.0796983 19.39 0.0796983 21.0114Z"/>
        </symbol>
        <symbol id="circle" viewBox="0 0 78 78">
          <title>circle</title>
          <circle fill="transparent" cx="39" cy="39" r="36" stroke-width="6"/>
        </symbol>
        <symbol id="circle-small" viewBox="0 0 78 78">
          <title>circle small</title>
          <circle fill="transparent" cx="39" cy="39" r="36" stroke-width="3"/>
        </symbol>
        <symbol id="reload" viewBox="0 0 24 27">
          <title>reload</title>
          <path d="M1.69417 8.52986C2.11031 7.86385 2.97068 7.64182 3.66438 8.03049C4.35808 8.44663 4.55242 9.33465 4.13607 10.0284C3.16479 11.6101 2.69312 13.4969 2.88746 15.5225C3.3036 19.7956 6.82768 23.1809 11.1009 23.4862C16.2898 23.8469 20.6185 19.6847 20.563 14.579C20.5074 9.83402 16.6227 5.89389 11.8778 5.81065H11.8223V7.91945C11.8223 8.52991 11.1563 8.91838 10.6292 8.58545L4.94086 5.0337C4.44149 4.72846 4.44149 4.00707 4.94086 3.70184L10.6015 0.122246C11.1287 -0.210671 11.7945 0.177797 11.7945 0.788249V2.9249C18.5373 2.98045 23.9204 8.6965 23.421 15.5503C23.0048 21.2941 18.3708 25.928 12.627 26.3443C5.74545 26.8715 0.00160599 21.4051 0.00160599 14.6346C-0.0260715 12.4147 0.612062 10.3059 1.69424 8.53001L1.69417 8.52986Z"/>
        </symbol>
        <symbol id="close" viewBox="0 0 34 34">
          <title>Close</title>
          <path d="M4.5 4.5L29 29" stroke-width="6" stroke-linecap="round"/>
          <path d="M4.5 29L29 4.5" stroke-width="6" stroke-linecap="round"/>
        </symbol>
        <symbol id="menu" viewBox="0 0 24 18">
          <title>Menu</title>
          <path d="M2.14282 2.42847H21.9013" stroke-width="3" stroke-linecap="round"/>
          <path d="M2.14282 9.15454H21.9013" stroke-width="3" stroke-linecap="round"/>
          <path d="M2.14282 15.8813H21.9013" stroke-width="3" stroke-linecap="round"/>
        </symbol>
        <symbol id="i" viewBox="0 0 8 26">
          <title>i</title>
          <circle cx="3.99975" cy="3.91283" r="3.57885" fill="white"/>
          <path d="M3.99952 12.5371L3.99951 23.0002" stroke="white" stroke-width="4.7718" stroke-linecap="round"/>
        </symbol>
        <symbol id="ired" viewBox="0 0 8 26">
          <title>ired</title>
          <circle cx="3.99975" cy="3.91283" r="3.57885" fill="#FF7E98"/>
          <path d="M3.99952 12.5371L3.99951 23.0002" stroke="#FF7E98" stroke-width="4.7718" stroke-linecap="round"/>
        </symbol>
        <symbol id="arrow-right--small" viewBox="0 0 55 48">
          <title>arrow-right--small</title>
          <path d="M0 42.3125V5.35203C0 1.82363 3.55605 -0.594786 6.83754 0.701931L50.9058 18.116C55.0298 19.7456 55.149 25.5388 51.0955 27.3367L7.02727 46.8831C3.72121 48.3495 0 45.9292 0 42.3125Z"/>
        </symbol>
        <symbol id="arrow-left--small" viewBox="0 0 55 48">
          <title>arrow-left--small</title>
          <path d="M55 42.3125V5.35203C55 1.82363 51.4439 -0.594786 48.1625 0.701931L4.09422 18.116C-0.0297546 19.7456 -0.148964 25.5388 3.90449 27.3367L47.9727 46.8831C51.2788 48.3495 55 45.9292 55 42.3125Z"/>
        </symbol>
      </defs>
    </svg>
    <!-- <svg preserveAspectRatio="xMidYMid meet">
      <use xlink:href="#arrow-left"/>
    </svg> -->
  </div>
</template>


