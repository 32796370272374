const isMobile = () => {
	if (
		navigator.userAgent.match(/Android/i) ||
		navigator.userAgent.match(/webOS/i) ||
		navigator.userAgent.match(/iPhone/i) ||
		navigator.userAgent.match(/iPad/i) ||
		navigator.userAgent.match(/iPod/i) ||
		navigator.userAgent.match(/BlackBerry/i) ||
		navigator.userAgent.match(/Windows Phone/i)
	) {
		return true
	} else {
		return false
	}
}

const lock = (orientation) => {
	// Go into full screen first
	if (document.documentElement.requestFullscreen) {
		document.documentElement.requestFullscreen()
	} else if (document.documentElement.mozRequestFullScreen) {
		document.documentElement.mozRequestFullScreen()
	} else if (document.documentElement.webkitRequestFullscreen) {
		document.documentElement.webkitRequestFullscreen()
	} else if (document.documentElement.msRequestFullscreen) {
		document.documentElement.msRequestFullscreen()
	}

	// Then lock orientation
	// screen.orientation.lock( orientation )
}

const unlock = () => {
	// Unlock orientation
	// screen.orientation.unlock()

	// Exit full screen
	if (document.exitFullscreen) {
		document.exitFullscreen()
	} else if (document.webkitExitFullscreen) {
		document.webkitExitFullscreen()
	} else if (document.mozCancelFullScreen) {
		document.mozCancelFullScreen()
	} else if (document.msExitFullscreen) {
		document.msExitFullscreen()
	}
}

const getEmojiWhithoutSkinTone = (unified) => {
	const unifiedToArr = unified.split('-')
	const stringForbidden = ['1F3FA', '1F3FB', '1F3FC', "1F3FD", "1F3FE", "1F3FF"]
	const unifiedWhitouhSkinTones = unifiedToArr.filter(code => !stringForbidden.includes(code))

	return unifiedWhitouhSkinTones.join('-')
}
const shuffle = (array) => {
	return array.sort(() => Math.random() - 0.5)
}

const toHtmlCode = (str) => {
	return str.replace(/-/g, ";&#x")
}

const lastIndex = (array, cond) => {
	if (!array.length) return -1
	if (!cond) return array.length - 1

	for (let i = array.length - 1; i >= 0; --i) {
		if (cond(array[i])) return i
	}

	return -1
}

const fillZero = (num) => {
	return num < 10 ? '0' + num : num
}

const manageDocHeight = () => {
	function setDocHeight() {
		// apply to html
		const wrapperMobile = document.querySelector('.mobile')
		//   document.documentElement.style.height = window.innerHeight + "px"
		wrapperMobile.style.height = window.innerHeight + "px"
	}
	setDocHeight()
	window.addEventListener('orientationchange', setDocHeight)
	window.addEventListener('resize', setDocHeight)
}

const getCookie = (cname) => {
	var name = cname + "="
	var decodedCookie = decodeURIComponent(document.cookie)
	var ca = decodedCookie.split(';')
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i]
		while (c.charAt(0) === ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ""
}


const Timer = function (callback, delay) {
	var timerId, start, remaining = delay;

	this.pause = function () {
		window.clearTimeout(timerId);
		timerId = null;
		remaining -= Date.now() - start;
	};

	this.resume = function () {
		if (timerId) {
			return;
		}

		start = Date.now();
		timerId = window.setTimeout(callback, remaining);
	};

	this.resume();
};

export { isMobile, lock, unlock, getEmojiWhithoutSkinTone, shuffle, toHtmlCode, lastIndex, fillZero, manageDocHeight, getCookie, Timer }
