import {
	Fog,
	AmbientLight,
	Vector3,
	Quaternion,
	Mesh,
	Color,
	DirectionalLight,
	PlaneBufferGeometry,
} from 'three'

import gsap, { Power4 } from 'gsap/all'


import Scene from '../abstractions/Scene'
import DebugCamera from '../components/cameras/DebugCamera'
import DebugStore from '../../store/DebugStore'
import Character from '../components/character/Character'
import GameStore from '../../store/GameStore'
import Assets from '../controllers/Assets'
import gui from '../utils/gui'
import AppStore from '../../store/AppStore'
import Coin from '../components/coin/coin'
import { getSpawnDatas, lastByLane, zSpace } from '../controllers/SpawnObject'
import { gameConfig, slugCharacter } from '../config/gameConfig'
import Barriers from '../components/barrier/Barriers'
import Floor from '../components/floor/Floor'
import Decors from '../components/decors/Decors'
import { Game } from '../../controllers'
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { PMREMGenerator } from 'three'
import { DirectionalLightHelper } from 'three'

import { MeshPhysicalMaterial } from 'three'

import { emitter } from '@/utils/emitter'
import { events } from '../config/events'
import Sky from '../components/sky/Sky'
import { Texture } from 'three'
import Files from '../../managers/assetsManager/Files'
import { CameraHelper } from 'three'
import Renderer from '../controllers/Renderer'
import { Expo } from 'gsap'
import Logo from '../components/logo/Logo'
import { isMobile, Timer } from '../../utils'
import Yellow from '../components/obstacles/Yellow'
import Corona from '../components/obstacles/Corona'
import Scandal from '../components/obstacles/Scandal'
import Twitter from '../components/obstacles/Twitter'
// import {worldColorParsChunk, wordlPosChunk, wor} from '../shaders/curvedWorld'

export default class GameScene extends Scene {

	constructor() {
		super()
		/// #if DEVELOPMENT
		this.devTools()
		/// #endif
	}

	init() {
		// this.base.fog = (new Fog(0xDADADA, 50, 90))
		const pmremGenerator = new PMREMGenerator(Renderer.getRenderer())
		const sceneEnv = new RoomEnvironment()
		this.base.environment = pmremGenerator.fromScene(sceneEnv, 0.04).texture;

		sceneEnv.traverse((o) => {
			if (o.geometry) {
				o.geometry.dispose()
			}

			if (o.material) {
				if (o.material.length) {
					for (let i = 0; i < o.material.length; ++i) {
						o.material[i].dispose()
					}
				}
				else {
					o.material.dispose()
				}
			}
		})

		pmremGenerator.dispose();

		this.spawnObjectArr = []
		this.bodies = []
		const camPosMobile = new Vector3(0, 13, -18)
		const camPosDesktop = new Vector3(0, 15.5, -20)
		this.camPos = isMobile() ? camPosMobile : camPosDesktop
		this.defaultCamera.cam.position.set(this.camPos.x, this.camPos.y, this.camPos.z)
		this.defaultCamera.cam.lookAt(0, 0, 0)

		// let cameraFolder = gui.folder('camera')
		// cameraFolder.add(this.defaultCamera.cam.position, 'x', 0, 30).name('cam x')
		// cameraFolder.add(this.defaultCamera.cam.position, 'y', 0, 30).name('cam y')
		// cameraFolder.add(this.defaultCamera.cam.position, 'z', -30, 0).name('cam z')

		this.camLookat = new Vector3(0, 0, 0)

		this.pause = false
		this.scalarValue = 1
		this.offset = 0
		this.initCamPos = new Vector3(0, 30, -20)

		this.last = 0
		this.camXToMove = 0
		this.lastRun = 0
		this.quizzMode = false
		this.showQuestion = false
		this.isReady = false
		this.isStarted = false
		this.timer = null
		this.logoVisible = true
		this.mouseCanMove = false

		this.score = 0
		this.characterScore = 0
		this.currentCharacter = 1

		this.previousGlobalSpeed = 0

		this.mouse = { x: 0.5, y: 0.5 }

		this.spawnDatas = getSpawnDatas()

		this.initLights()
		this.initSky()
		this.initGround()
		this.initLogo()
		this.initBarricade()
		this.initDecors()
		this.onChangeView(AppStore.currentView.get())
		this.bind()
		this.enter()

		this.base.rotation.y = Math.PI;

		if(gameConfig.globalSpeed == 1) gameConfig.globalSpeed = 0 

	}

	bind() {
		this.onPlayerReady = this.onPlayerReady.bind(this)
		this.onQuizMode = this.onQuizMode.bind(this)
		this.onPositionChange = this.onPositionChange.bind(this)
		this.handleAnswer = this.handleAnswer.bind(this)
		this.onGameReady = this.onGameReady.bind(this)
		this.onGameStarted = this.onGameStarted.bind(this)
		this.handlePause = this.handlePause.bind(this)
		this.handleResume = this.handleResume.bind(this)
		this.handleMouse = this.handleMouse.bind(this)
		this.onReset = this.onReset.bind(this)
		this.initCharacters = this.initCharacters.bind(this)

		AppStore.currentView.subscribe(this.onChangeView, this)
		AppStore.keyDown.subscribe(this.onPositionChange, this)
		AppStore.swipe.subscribe(this.onPositionChange, this)

		GameStore.playerReady.subscribe(this.onPlayerReady, this)
		GameStore.quizzMode.subscribe(this.onQuizMode, this)
		GameStore.ready.subscribe(this.onGameReady, this)
		GameStore.started.subscribe(this.onGameStarted, this)

		GameStore.reset.subscribe(this.onReset, this)
		window.addEventListener('mousemove', this.handleMouse)

		emitter.on(events.HIDE_QUESTION, this.handleAnswer)
		emitter.on(events.PAUSE, this.handlePause)
		emitter.on(events.RESUME, this.handleResume)
		emitter.on(events.CANDIDATES, this.initCharacters)

	}

	unbind() {
		AppStore.currentView.unsubscribe(this.onChangeView, this)

		GameStore.playerReady.unsubscribe(this.onPlayerReady, this)
		GameStore.quizzMode.unsubscribe(this.onQuizMode, this)
		GameStore.ready.unsubscribe(this.onGameReady, this)
		GameStore.started.unsubscribe(this.onGameStarted, this)

		AppStore.keyDown.unsubscribe(this.onPositionChange)
		GameStore.reset.unsubscribe(this.onReset, this)

		window.removeEventListener('mousemove', this.handleMouse)

		emitter.off(events.HIDE_QUESTION, this.handleAnswer)
		emitter.off(events.PAUSE, this.handlePause)
		emitter.off(events.RESUME, this.handleResume)
		emitter.off(events.CANDIDATES, this.initCharacters)

	}


	onChangeView(view) {
		// TweenLite.fromTo(this, .7, { scalarValue: this.scalarValue }, { scalarValue: .5, ease: Power3.easeIn })
		// TweenLite.fromTo(this, .7, { offset: this.offset }, { offset: .9, ease: Power3.easeIn })
	}

	onGameReady(ready) {
		console.log('GAME REAADY', ready)
		if (!ready) return
		this.character = this.characters[0]
		this.addComponent(this.character)
		this.addComponent(this.character.particlesDizzy)
		this.addComponent(this.character.particlesGrimacing)
		this.addComponent(this.character.particlesSmiling)
		this.character.onEnterAnimation({ delay: 2 }).then(() => {
			// GAME STARTED
			this.wraitToQuizzMode()
		})
		this.isReady = true
		gsap.to(gameConfig, { globalSpeed: 1, duration: 10 })
		gameConfig.speed = 1
	}

	onGameStarted(started) {
		if (!started) return
		this.isStarted = true
		console.log('GAME STARTED')
		// this.wraitToQuizzMode()
	}

	onReset() {
		console.log('RESET')
		this.pause = false
		this.scalarValue = 1
		this.offset = 0
		this.initCamPos = new Vector3(0, 30, -20)

		this.last = 0
		this.camXToMove = 0
		this.lastRun = 0
		this.quizzMode = false
		this.showQuestion = false
		this.isReady = false
		this.isStarted = false
		this.timer = null
		this.logoVisible = true
		this.mouseCanMove = false

		this.score = 0
		this.characterScore = 0
		this.currentCharacter = 1
		lastByLane[1] = null
		lastByLane[2] = null
		lastByLane[3] = null

		GameStore.canAnswer.set(false)

		for (let i = 1; i < 4; i++) {
			GameStore.characterDatas.get()[i].question.set(1)
			GameStore.characterDatas.get()[i].score.set(0)
		}


		if (this.characters.length > 0) {
			this.characters.forEach((character, index) => {
				this.characters[index].unbind()
				delete this.characters[index]
			})
		}


		if (this.timer) clearTimeout(this.timer);


	}

	onPlayerReady(isReady) {
	}

	onQuizMode(e) {
		this.quizzMode = e
	}

	handleMouse(event) {
		this.mouse.x = event.clientX / window.innerWidth - 0.5
		this.mouse.y = event.clientY / window.innerHeight - 0.5
	}

	wraitToQuizzMode() {
		this.timer = new Timer(() => {
			Game.setQuizzMode()
			this.onQuizMode(true)
			console.log('QUIZZ MODE')
		}, Game.getRunningTime() * 1000)
		// setTimeout(() => {
		// 	// Game.setQuizzMode()
		// 	// this.onQuizMode(true)
		// 	// console.log('QUIZZ MODE')
		// 	// this.spawnObjectArr.forEach((object) => {
		// 	// 	object.hide()
		// 	// })
		// })
	}

	endGame() {

	}


	initLights() {
		const directionalLight = new DirectionalLight(0xFFFFFF, .7)
		directionalLight.castShadow = true
		this.add(directionalLight)

		directionalLight.position.y = 20
		directionalLight.position.x = 15
		directionalLight.position.z = 0
		directionalLight.rotation.z = -Math.PI * 2

		directionalLight.shadow.camera.near = .1
		directionalLight.shadow.camera.far = 50
		directionalLight.shadow.camera.top = 25
		directionalLight.shadow.camera.right = 25
		directionalLight.shadow.camera.bottom = -25
		directionalLight.shadow.camera.left = -25
		directionalLight.shadow.mapSize.width = 512
		directionalLight.shadow.mapSize.width = 512
		directionalLight.shadow.mapSize.height = 512

		// const ambiant = new AmbientLight(0xFFFFFF, 0)
		// this.add(ambiant)

		// const dirLightHelper = new DirectionalLightHelper(directionalLight, 5);
		// this.add(dirLightHelper);
		if (DebugStore.useGui.current) {
			const dirFolder = gui.folder('Directional')
			dirFolder.add(directionalLight.position, 'x', -20, 20)
		}

		// const ambiantFolder = gui.folder('ambiant')
		// ambiantFolder.add(ambiant, 'intensity', 0, 1)

		// this.addComponent(item)
		// let f = gui.folder('survey')
		// f.add(item.rotation, 'x', 0, 10)
		// f.add(item.rotation, 'y', 0, 10)
		// f.add(item.rotation, 'z', 0, 10)
		// const dirLightHelper = new DirectionalLightHelper(dirLight, 10);
		// this.add(dirLightHelper);
	}

	initDecors() {
		this.decors = new Decors()
		this.decors.tiles.forEach((tile) => {
			tile.forEach((decor) => {
				// console.log('DECOR', decor)
				this.addComponent(decor)
			})
		})
	}

	initCharacters(datas) {
		// console.log('init character', slugCharacter[GameStore.characterDatas.get()[1].name.get()], GameStore.characterDatas.get()[1].name.get())
		// console.log('init character', slugCharacter[GameStore.characterDatas.get()[2].name.get()], GameStore.characterDatas.get()[2].name.get())
		// console.log('init character', slugCharacter[GameStore.characterDatas.get()[3].name.get()], GameStore.characterDatas.get()[3].name.get())
		datas.forEach((candidate, index) => {
			GameStore.characterDatas.get()[index + 1].name.set(candidate.slug.toLowerCase())
		})

		const candidat1 = slugCharacter[GameStore.characterDatas.get()[1].name.get()]
		const candidat2 = slugCharacter[GameStore.characterDatas.get()[2].name.get()]
		const candidat3 = slugCharacter[GameStore.characterDatas.get()[3].name.get()]
		// console.log('INIT CHARACTERS')
		this.characters = []
		let id = 1
		// const characterToPlay = [slugCharacter[GameStore.characterDatas.get()[1].name.get(), GameStore.characterDatas.get()[2].name.get(), GameStore.characterDatas.get()[3].name.get()]
		const characterToPlay = [candidat1, candidat2, candidat3]
		characterToPlay.forEach((characterName) => {
			let character = new Character({
				id: id,
				model: characterName,
				position: new Vector3(0, 1.2, -20),
				scale: new Vector3(.95, .95, .95)
			})
			id++
			this.characters.push(character)
		})
		// this.character = this.characters[0]

		// this.addComponent(this.character)
	}

	enter() {
		super.enter()
		gsap.from(this.defaultCamera.cam.position, { x: this.initCamPos.x, z: this.initCamPos.z, y: this.initCamPos.y, duration: 6, delay: .3, ease: Expo.easeOut })
		gsap.from(this.logo.base.position, {
			z: 20, duration: 3, delay: .3, ease: Expo.easeOut, onComplete: (() => {
				this.mouseCanMove = true
			})
		})
		// gsap.to(this.defaultCamera.cam.rotation, { x: .00000001, duration: .5, delay: .4 })
		// this.character = this.characters[0]
		// this.addComponent(this.character)
		// this.character.onEnterAnimation({ duration: null }).then(() => {
		// 	// GAME STARTED
		// 	this.wraitToQuizzMode()
		// })
		// this.isReady = true
		// this.isStarted = true
		// TODO/ EVENT START
	}

	handlePause() {
		if (this.character && this.character.mixer) this.character.mixer.timeScale = 0
		this.previousGlobalSpeed = gameConfig.globalSpeed
		gameConfig.globalSpeed = 0
		gameConfig.speed = 0
		this.pause = true
		if (this.character && this.character.gsapEnter) this.character.gsapEnter.pause() 
		if (this.character) this.character.canMove = false
		if (this.timer) this.timer.pause();
	}

	handleResume() {
		if (this.timer) this.timer.resume();
		if (this.character && this.character.mixer) gsap.to(this.character.mixer, { timeScale: 1, duration: .3 })
		if (this.isStarted) gsap.to(gameConfig, { globalSpeed: this.previousGlobalSpeed, duration: .3 })
		if (this.isStarted) gsap.to(gameConfig, { speed: 1, duration: .3 })
		if (this.character && this.character.gsapEnter) this.character.gsapEnter.play() 
		if (this.character) this.character.canMove = true
		this.pause = false
		// this.character.mixer.timeScale = 1
		// gameConfig.globalSpeed = 1
		// gameConfig.speed = 1
	}

	initSky() {
		this.sky = new Sky()
		this.addComponent(this.sky)
	}

	initGround() {
		// const groundMaterial = new CurvedMaterial({ color: new Color(Colors.darkBLue) })
		// const material = new MeshPhysicalMaterial({ color: new Color(0x07144B), metalness: .2, specularIntensity: 1, specularColor: new Color(0xffffff), roughness: .6 })
		const material = new MeshPhysicalMaterial({ color: 0x050E1F })
		const colorParsChunk = [
			'vec3 displace(vec3 v) {',
			'float dist = distance(v.z, cameraPosition.z);',
			'float distX = distance(v.x, cameraPosition.x);',
			'if( dist < 0.0) { dist = 0.0; }',
			'if( distX < 0.0) { distX = 0.0; }',
			'float addY = dist * dist;',
			'float addX = distX * distX;',
			`return vec3(v.x, v.y - (addY * (1. *${gameConfig.bendY.toString()})) -  (addX * (1. * 0.002)), v.z);`,
			'}',
			'#include <common>'
		].join('\n');

		const instanceColorChunk = [
			'#include <worldpos_vertex>',
			'transformed = worldPosition.xyz;',
			'transformed = displace(transformed);',
			'	float e = -.01;',
			'vec3 dA = e * normalize(cross(normal.yzx, normal));',
			'vec3 dB = e * normalize(cross(dA, normal));',
			'vec3 pA = worldPosition.xyz + dA ;',
			'vec3 pB = worldPosition.xyz + dB ;',
			'vec3 hA = displace(pA);',
			'vec3 hB =  displace(pB);',
			'vec4 testNormal = vec4(cross(hA-transformed, hB-transformed), 1.);',
			'vNormal = normalize(normalMatrix * testNormal.xyz);',
			'gl_Position = projectionMatrix * viewMatrix * vec4(transformed, 1.);'
		].join('\n');

		const colorChunk = [
			'vec4 diffuseColor = vec4( diffuse , opacity );'
			// 'vec4 diffuseColor = vec4( vNormal, opacity );'
		].join('\n');

		material.onBeforeCompile = function (shader) {

			shader.vertexShader = shader.vertexShader
				.replace('#include <common>', colorParsChunk)
				.replace('#include <worldpos_vertex>', instanceColorChunk);

			shader.fragmentShader = shader.fragmentShader
				// .replace('#include <common>', fragmentParsChunk)
				.replace('vec4 diffuseColor = vec4( diffuse, opacity );', colorChunk);

			//console.log( shader.uniforms );
			//console.log( shader.vertexShader );
			//console.log( shader.fragmentShader );

		};

		this.ground = new Mesh(new PlaneBufferGeometry(100, 100, 32, 32), material)
		this.ground.rotation.x = -Math.PI / 2
		this.ground.receiveShadow = true
		this.add(this.ground)

		this.floor = new Floor()
		this.addComponent(this.floor)

	}

	initLogo() {
		this.logo = new Logo()
		this.addComponent(this.logo)
	}

	hitCoin(object) {
		this.score += gameConfig.hitCoin
		this.characterScore += gameConfig.hitCoin
		GameStore.score.set(this.score)
		GameStore.characterDatas.current[this.currentCharacter].score.set(this.characterScore)

		this.animateHideCoin(object)

		emitter.emit(events.BONUS)

		// console.log("SCORE GLOBAL ==== ", this.score)
		// console.log("SCORE  === ", this.characterScore, "CHARACTER ==", this.currentCharacter)
	}

	animateHideCoin(coin) {
		gsap.to(coin.base.scale, {
			y: 0, x: 0, z: 0, duration: 0, onComplete: (() => {
				const i = this.spawnObjectArr.indexOf(coin)
				this.spawnObjectArr.splice(i, 1);
				this.removeComponent(coin)
			})
		})
	}

	hitObstacle() {
		this.cameraShake()
		this.character.hitAnimation()
		this.score += gameConfig.hitObstacle
		this.characterScore += gameConfig.hitObstacle
		if (this.score < 0) this.score = 0
		if (this.characterScore < 0) this.characterScore = 0
		GameStore.score.set(this.score)
		GameStore.characterDatas.current[this.currentCharacter].score.set(this.characterScore)

		// console.log("SCORE ==== ", this.score)

		// console.log("SCORE  === ", this.characterScore, "CHARACTER ==", this.currentCharacter)
		emitter.emit(events.MALUS)

	}


	generateSpawnObject(spawnDatas) {
		// console.log(spawnDatas.lane)
		switch (spawnDatas.type) {
			case 'obstacle':
				this.generateObstacles(spawnDatas)
				break;
			case 'coin':
				this.generateCoins(spawnDatas)
				break;

		}
		this.spawnDatas = getSpawnDatas()
	}

	onPositionChange(key) {
		if (!this.isReady) return
		if (!this.character.canMove) return
		switch (key.code) {
			case 'KeyA':
			case 'KeyQ':
			case 'ArrowLeft':
			case 'left':
				this.cameraToLeft()
				this.character.goToLeft()
				break;
			case 'KeyD':
			case 'ArrowRight':
			case 'right':
				this.cameraToRight()
				this.character.goToRight()

				break;
		}
	}

	generateCoins(spawnDatas) {
		for (let i = 0; i < spawnDatas.coin; i++) {

			const lastZ = lastByLane[spawnDatas.lane] ? lastByLane[spawnDatas.lane].base.position.z : null
			let zpos = lastZ && lastZ >= 35 && i === 0 ? lastZ + zSpace + (zSpace * i) : 45 + (zSpace * i)
			let coin = new Coin({ position: new Vector3(gameConfig.xLaneCoin[spawnDatas.lane], gameConfig.coinY, zpos) })
			this.spawnObjectArr.push(coin)
			this.addElemntInLaneObect(spawnDatas.lane, coin)
			this.addComponent(coin)
		}
	}

	cameraShake() {
		// TODO
		if (this.isShaking) return
		this.isShaking = true
		let tl = gsap.timeline({
			onComplete: (() => {
				this.isShaking = false
			})
		})
		tl.to(this.defaultCamera.cam.rotation, { y: this.camXToMove + .0045, duration: .2 })
		tl.to(this.defaultCamera.cam.rotation, { y: this.camXToMove + -.0045, duration: .2 })
		tl.to(this.defaultCamera.cam.rotation, { y: this.camXToMove + .0045, duration: .2 })
		tl.to(this.defaultCamera.cam.rotation, { y: this.camXToMove + -.0045, duration: .2 })
		tl.to(this.defaultCamera.cam.rotation, { y: this.camXToMove, duration: .2 })

		tl.play()
	}

	cameraToLeft() {
		if (this.character.lane == 0) return

		// console.log(this.character.lane)

		this.camXToMove -= gameConfig.cameraToTranslate
		if (!this.isShaking) {
			gsap.to(this.defaultCamera.cam.rotation, { y: this.camXToMove, duration: .2 })
		}


	}

	cameraToCenter({ delay, duration }) {

		this.camXToMove = 0

		gsap.to(this.defaultCamera.cam.rotation, { y: this.camXToMove, duration: duration || .2, delay: delay || 0 })

	}

	cameraToRight() {
		if (this.character.lane === 2) return

		this.camXToMove += gameConfig.cameraToTranslate
		if (!this.isShaking) {
			gsap.to(this.defaultCamera.cam.rotation, { y: this.camXToMove, duration: .2 })
		}
	}

	generateObstacles(spawnDatas) {
		let lastZ = lastByLane[spawnDatas.lane] ? lastByLane[spawnDatas.lane].base.position.z : null
		let zpos = lastZ && lastZ >= 35 ? lastZ + zSpace : 45
		switch (spawnDatas.obstacleName) {
			case 'yellow': {
				let yellow = new Yellow({
					position: new Vector3(gameConfig.xLaneCoin[spawnDatas.lane], 1.5, zpos,),
					scale: new Vector3(2, 2, 2)
				})
				this.spawnObjectArr.push(yellow)
				this.addElemntInLaneObect(spawnDatas.lane, yellow)
				this.addComponent(yellow)
			}
				break;

			case 'corona': {
				let corona = new Corona({
					position: new Vector3(gameConfig.xLaneCorona[spawnDatas.lane], 3.5, zpos),
					scale: new Vector3(1, 1, 1)
				})
				this.spawnObjectArr.push(corona)
				this.addElemntInLaneObect(spawnDatas.lane, corona)
				this.addComponent(corona)
			}
				break;

			case 'twitter': {
				let twitter = new Twitter({
					position: new Vector3(gameConfig.xLaneTwitter[spawnDatas.lane], 3.25, zpos),
					scale: new Vector3(38, 38, 38)
				})
				this.spawnObjectArr.push(twitter)
				this.addElemntInLaneObect(spawnDatas.lane, twitter)
				this.addComponent(twitter)
			}
				break;
	

			case 'scandal': {
				let scandal = new Scandal({
					position: new Vector3(gameConfig.xLaneCorona[spawnDatas.lane], 2.75, zpos),
					scale: new Vector3(1.5, 1.5, 1.5)
				})
				this.spawnObjectArr.push(scandal)
				this.addElemntInLaneObect(spawnDatas.lane, scandal)
				this.addComponent(scandal)
			}
				break;
		}
	}

	addElemntInLaneObect(lane, object) {
		lastByLane[lane] = object
	}

	initBarricade() {
		this.barrier = new Barriers()
		this.addComponent(this.barrier)
	}

	handleAnswer(answer) {
		// console.log('HAS ANSWER')
		if(answer.value) {
			this.character.particlesSmiling.playAnimationGoodAnswer()
		} else {
			this.character.particlesGrimacing.playAnimationBadAnswer()
		}
		setTimeout(() => {
			this.character.hasAnswer = true
			let nbQuestion = GameStore.characterDatas.current[this.currentCharacter].question.current
			// console.log('NB QUESTION ===', nbQuestion)
			if (nbQuestion === gameConfig.questionPerCharacter && this.currentCharacter < 3) {
				// Handle passation
				this.onSwitchOver()
			} else if (nbQuestion === gameConfig.questionPerCharacter && this.currentCharacter === 3) {
				// handle end game
				// console.log('END GAME', this.character)
				this.character.handleEndGame()
				emitter.emit(events.END)
			} else {
				// console.log('INCREMENT QUESTION', Number(nbQuestion) + 1)
				GameStore.characterDatas.current[this.currentCharacter].question.set(Number(nbQuestion) + 1)
				Game.setRunMode()
				this.wraitToQuizzMode()
				this.showQuestion = false
				this.character.resetQuizzMode()
				gameConfig.speedSpawner += .1
				gameConfig.coinSpeed += .04
				// this.character.mixer.timeScale += .1
			}
		}, 1400)
	}

	onSwitchOver() {
		this.currentCharacter++
		this.characterScore = 0
		gameConfig.speedSpawner = 1.5
		gameConfig.coinSpeed = .65
		GameStore.character.set(this.currentCharacter)
		this.character.canMove = false
		this.character.resetQuizzMode()
		const previousCharacter = this.character
		this.character.hide().then(() => {
			this.character = this.characters[this.currentCharacter - 1]
			this.addComponent(this.character)
			this.addComponent(this.character.particlesDizzy)
			this.addComponent(this.character.particlesGrimacing)
			this.addComponent(this.character.particlesSmiling)
			this.removeComponent(previousCharacter)
			this.removeComponent(previousCharacter.particlesSmiling)
			this.removeComponent(previousCharacter.particlesGrimacing)
			this.removeComponent(previousCharacter.particlesDizzy)
			this.character.onEnterAnimation({ duration: 2 }).then(() => {
				setTimeout(() => {
					Game.setRunMode()
					this.wraitToQuizzMode()
					this.showQuestion = false
					this.character.resetQuizzMode()
				}, 2000)
			})
			// console.log("SWITCH OVER")

			emitter.emit(events.CANDIDATE_CHANGE)
		})
	}


	destroy() {
		/// #if DEVELOPMENT
		this.removeComponent(this.debugCamera)
		DebugStore.debugCamera.unsubscribe(this.toggleOrbitControl, this)
		DebugStore.debugPhysics.unsubscribe(this.toggleDebugPhysics, this)
		this.physicsDebugContainer = null
		this.debugCamera = null
		/// #endif

		this.ground.geometry.dispose()
		this.remove(this.ground)

		this.unbind()
		super.destroy()
	}

	update(dt) {
		if (this.pause) return
		super.update(dt)
		if (this.decors) this.decors.sceneUpdate(this)

		// this.defaultCamera.cam.updateProjectionMatrix()

		if (this.character && this.character.isEnter) {
			if (!this.isStarted) return
			this.spawnObjectArr.forEach((object, index) => {
				object.sceneUpdate()
				// console.log('GO GOGO ')
				if (object.checkCollistion(this.character.body) && !object.isCollide) {
					if (object.name == "coin") {
						this.hitCoin(object)
					} else {
						this.hitObstacle()
					}
					object.isCollide = true
				}
				if (object.base.position.z < -15) {
					const index = this.spawnObjectArr.indexOf(object)
					this.spawnObjectArr.splice(index, 1);
					this.removeComponent(object)
				}
			})


			if (this.quizzMode && !this.showQuestion && this.spawnObjectArr.length === 0) {
				// console.log('CENTER CHARACTER')
				this.character.canMove = false
				this.cameraToCenter({ delay: 1, duration: .3 })
				this.character.goTocenter({ delay: 1, duration: .3 }).then(() => {
					emitter.emit(events.SHOW_QUESTION)
					GameStore.canAnswer.set(true)
				})
				this.showQuestion = true

			}

			if (!this.last || Date.now() - this.last >= this.spawnDatas.timeToSpawn * 1000) {
				if (!this.quizzMode) {
					this.last = Date.now()
					this.generateSpawnObject(this.spawnDatas)
				}
			}

		}

		if (this.logo.base.position.z < -20 && this.logoVisible) {
			this.removeComponent(this.logo)
			this.logoVisible = false
		}

		if (this.logoVisible && this.mouseCanMove) {
			this.logo.base.rotation.y +=
				((this.mouse.x - 6) * -0.5 + - this.logo.base.rotation.y) * 0.05

			this.logo.base.rotation.x +=
				(this.mouse.y * -0.5 - this.logo.base.rotation.x) * 0.1

			this.defaultCamera.cam.position.x +=
				(this.mouse.x * -0.65 + -this.defaultCamera.cam.position.x) * 0.05
		}

		// this.defaultCamera.cam.position.copy( this.easedCamPos )
		/// #if DEVELOPMENT
		if (!this.physicsDebugContainer) return
		if (this.physicsDebugContainer.visible) {
			this.debugRenderer.update()
		}
		/// #endif
	}

	/// #if DEVELOPMENT
	devTools() {
		// this.physicsDebugContainer = this.add(new Object3D())
		this.debugCamera = this.addComponent(DebugCamera.get())
		this.debugCamera.cam.position.copy(this.defaultCamera.cam.position)
		DebugStore.debugCamera.subscribe(this.toggleOrbitControl, this)
		DebugStore.debugPhysics.subscribe(this.toggleDebugPhysics, this)
		this.toggleOrbitControl(DebugStore.debugCamera.get())
		// this.toggleDebugPhysics(DebugStore.debugPhysics.get())
	}

	toggleDebugPhysics(v) {
		this.physicsDebugContainer.visible = v
	}

	toggleOrbitControl(v) {
		this.debugCamera.base.position.copy(new Vector3(0, 0, 5))
		this.debugCamera.base.quaternion.copy(new Quaternion())

		if (v) this.useCamera(this.debugCamera)
		else this.previousCamera && this.useCamera(this.previousCamera)
	}
	/// #endif

}
