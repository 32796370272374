<template>
  <div class="u-wrapper-panel u-row">

    <!-- BACKGROUND VIDEO AND OVERLAY -->
    <div class="u-w8of12 u-w12of12--md u-full-height u-relative">
      <!-- <video class="u-pointer-none u-wrapper-panel u-fit-cover" autoplay loop muted src="/videos/temp.mp4" playsinline /> -->
      <app-image class="u-pointer-none u-wrapper-panel u-fit-cover" :id="data.media[0].id" :url="data.media[0].url" />
      <transition name="t-fader">
        <div v-if="!isOpen" class="u-row u-center">
          <candidat-info :data="data" class="u-w6of12 u-w10of12--sm" />
        </div>
      </transition>
      <app-button-round v-if="!$device.isMobile" :to="{name: 'Homepage'}" class="candidate__close" :big="true">
        <svg class="u-wrapper-panel u-fill-red" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#out"/>
        </svg>
      </app-button-round>
    </div>

    <!-- MOBILE -->
    <div class="candidate__btn u-row u-left u-middle">
      <div v-if="$device.isMobile" class="u-w2of12 u-marg-l-1of12">
        <app-button-round :to="{name: 'Homepage'}" class="u-full-width" :big="true">
          <svg class="u-wrapper-panel u-fill-red" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#out"/>
          </svg>
        </app-button-round>
      </div>
      <div v-if="!$device.isDesktop" class="u-w6of12 u-marg-l-3of12 u-marg-l-0--sm u-center">
        <app-button @click="onOpen" :isBlue="true" innerClass="t-h3">
          Découvrir
        </app-button>
      </div>
    </div>

    <!-- INFO PANEL -->
    <div class="candidate__panel u-w4of12 u-w12of12--md u-bg-white" :class="{'is-open': isOpen}">
      <transition name="t-fader">
        <app-button-round v-if="!$device.isDesktop && isOpen" @click="isOpen = false" class="candidate__panel__close" :big="true">
          <svg class="u-wrapper-panel u-stroke-red" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#close"/>
          </svg>
        </app-button-round>
      </transition>
      <div class="u-full-height u-full-width u-overflow-y-scroll u-invisible-scroll u-pad-lg">
        <template v-for="info in data.infoCandidat">
          <div v-if="info.type === 'chapter'" class="t-h2 u-marg-b-md u-marg-t-xs">
            {{ info.title }}
          </div>
          <div v-else="info.type === 'items'" class="u-row u-pad-md u-radius u-shadow u-middle u-marg-b-sm">
            <div class="u-w2of12">
              <app-image :id="info.picto[0].id" :width="150" class="u-block u-full-width" />
            </div>
            <div class="u-w9of12 t-h5 u-pad-l-md">
              {{ info.text }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CandidatInfo from '@/components/CandidatInfo'
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  components: {
    CandidatInfo,
    AppButtonRound
  },

  computed: {
    data () {
      return this.$store.getters['data/infosBySlug'](this.$route.params.candidat)
    }
  },

  data () {
    return {
      isOpen: false
    }
  },

  methods: {
    onOpen () {
      this.isOpen = true
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/easings'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.candidate
  &__close
    position absolute
    top: $spacings.md
    left: $spacings.md
    
  &__btn
    position absolute
    bottom: $spacings.md
    left 0
    width 100%

  &__panel
    height 100%

    +mq($until: 'desktop')
      position absolute
      border-radius 30px 30px 0 0
      bottom 0
      left 0
      height calc(100% - 150px)
      transform translateY(100%)
      transition transform 1.2s ease-in-out-quart
      z-index 1

      &.is-open
        transform translateY(0)

    &__close
      position absolute
      top -40px
      left calc(50% - 40px)
      
</style>