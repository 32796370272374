<template>
  <div class="homepage u-wrapper-panel">
    <slidable :margin=".1">
      <video ref="video" class="js-slidable-content u-pointer-none u-wrapper-panel" autoplay loop muted src="/videos/intro.mp4" playsinline />
      <transition-group name="t-fader">
        <keypoint
          v-if="!popinIntro"
          :position="{x: keypoint.position[0].x, y: keypoint.position[0].y}"
          v-for="(keypoint, index) in keypoints"
          :data="keypoint"
          :key="index"
        />
      </transition-group>
    </slidable>
    <transition name="t-fader">
      <div v-if="!popinIntro" class="homepage__next u-center">
        <app-button @click="sound" :isShiny="true" :to="{name: 'Game'}" :icon="true" innerClass="t-h3">
          <svg class="homepage__next__icon u-marg-r-sm u-fill-white" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#talk"/>
          </svg>
          Faire campagne
        </app-button>
      </div>
    </transition>

    <transition name="custom" duration="1200">
      <popin-intro v-if="popinIntro" @close="onIntroClose" />
    </transition>
  </div>
</template>

<script>
import Slidable from '@/components/Slidable'
import Keypoint from '@/components/Keypoint'
import PopinIntro from '@/components/PopinIntro'

import { audioBg } from '@/utils/Audio'

export default {
  components: {
    Slidable,
    Keypoint,
    PopinIntro
  },

  computed: {
    keypoints () {
      return this.$store.getters['data/infos']
    }
  },

  data () {
    return {
      popinIntro: false
    }
  },

  mounted () {
    if (this.$store.getters['global/isFirstHomepage']) {
      setTimeout(() => {
        this.popinIntro = true
        this.$store.commit('global/isFirstHomepage')
      }, 1000)
    }
  },

  methods: {
    onIntroClose () {
      this.popinIntro = false
      this.$refs.video.play()
    },
    sound () {
      if (!audioBg.isPlaying) {
        audioBg.play()
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.homepage
  &__next
    position absolute
    bottom $spacings.md
    left 0
    width 100%

    &__icon
      width 1.5em
      height 1.5em
</style>